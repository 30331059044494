import { Pagination } from "@/types/Pagination";

export const PaginationService = {
  getDefaultPagination(): Pagination {
    return {
      page: 1,
      search: "",
      items_per_page: 25,
    };
  },

  onPrimeVuePaginationChange(
    tablePagination: any,
    pagination: Pagination
  ): void {
    pagination.page = tablePagination.page + 1;
    pagination.items_per_page = tablePagination.rows;
  },
};
